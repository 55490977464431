import React, { Component } from 'react';
// import { Link as GatsbyLink } from "gatsby";
import { FormattedMessage } from 'react-intl';
import PriceCtn from '../price'
import AddToFavorite from '../AddToFavorite'
import {
  Badge,
  Box,
  Grid,
  Flex,
  Heading,
  Image,
  // Link,
  Stack,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import countryToFlag from '../../utils/countryToFlag'

import { BiCamera } from 'react-icons/bi'
// import css from './ad-item.module.scss';
import logo from "../../static/svg/logo.svg"
import { useContext } from 'react';
import { AdContext } from './adContext';
import Debug from '../../utils/Debug';

const AdCardLandscape = (props) => {


  const {
    action,
    contentful_id,
    name,
    price,
    currency,
    brand,
    region,
    country,
    images,
    seller,
    publicationDate,
    openSelf,
    isBoat,
    isSail,
    isPremium,
    openAction
  } = props;


  const adContext = useContext(AdContext);
  const setAd = adContext ? adContext.setAd : null;


  const displayThumbnail = (images, alt) => {
    if (images === null || images === undefined || !images.url) {
      return (
        <picture>
          <img loading="lazy" src={logo} alt={alt} />
        </picture>
      )
    }
    else if (images.url.length === 0) {
      return (
        <picture>
          <img loading="lazy" src={logo} alt={alt} />
        </picture>
      )
    }
    else {
      return (
        <Box
          position='relative'
          zIndex='base'
          borderRadius={2}
          overflow='hidden'
        >
          <picture>
            <source
              srcSet={
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + `?twic=v1/cover=96x96/format=webp/quality=50 1x,`
                + `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + `?twic=v1/cover=192x192/format=webp/quality=50 2x`
              }
              type="image/webp"
            />

            <Image
              src={
                // process.env.GATSBY_IMAGE_URL_PREFIX
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + '?twic=v1/cover=96x96/format=jpeg/quality=50'
                // + '?quality=70&fit=cover&width=96&height=96&format=webp'
              }
              w={{ base: '80px', lg: '96px' }}
              h={{ base: '80px', lg: '96px' }}

              htmlWidth='1'
              htmlHeight='1'
              loading='lazy'
              alt={alt}
            />
          </picture>
          <Flex
            position="absolute" bottom="0" left="0" w={'100%'}
            justify="flex-end"
            p={1}
            alignItems="flex-end"
            background="linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, rgba(26, 26, 26, 0.8) 100%)"
          >
            <Text
              as="span"
              fontSize="12px"
              color="white"
              pr={1}
            >
              {images.url.length}
            </Text>
            <BiCamera color='white' />
          </Flex>
        </Box>
      );
    }
  }

  // return(
  //   <Debug data={ isInIframe } />
  // )
  return (
    <Box
      className="item-card"
      as={"article"}
      display="block"
      p={{ base: 2, lg: 3 }
      }
      mb={5}
      background={useColorModeValue("white", "whiteAlpha.50")}
      shadow="xs"
      borderRadius={3}
      border="solid 2px"
      borderColor={isPremium ? "blue.brand" : "transparent"}
      _hover={{
        borderColor: "blue.200",
        cursor: "pointer"
      }
      }
      onClick={() => {
        !openSelf ?
        // console.log('contentful_id',openSelf, contentful_id)
          setAd(contentful_id)
        :
          console.log('contentful_id',openSelf, contentful_id)
          openAction()
        // console.log('coucou')
      }}
    >
      <Grid
        templateColumns={{ base: "80px calc(95% - 80px)", lg: "96px calc(100% - 96px - 50px - 30px) 50px" }}
        gridGap={"15px"}>
        <Box>
          {displayThumbnail(images, name)}
        </Box>
        <Box>
          <Stack isInline spacing={{ base: 0, lg: 2 }}>
          {/* <Debug data={ [openSelf, contentful_id] } /> */}
            <Box
              display={{ base: 'none', lg: action ? 'block' : 'none' }}
              fontSize={{ base: 11, lg: 12 }}
              lineHeight={{ base: 1, lg: 2 }}
              px={2}
              py={0.75}
              borderRadius={3}
              bg='blue.brand'
              color='white'
              textTransform='uppercase'
              whiteSpace='pre'
            >
              {action === 'sell' ?
                <FormattedMessage id='main.sell' />
                : action === 'search' ?
                  <FormattedMessage id='main.search' />
                  : action === 'rent' ?
                    <FormattedMessage id='main.rent' />
                    : null
              }
            </Box>
            <Heading
              as="h4"
              w={'100%'}
              fontWeight="400"
              fontSize={{ base: "md", lg: 'lg' }}
              isTruncated
              pt={{ base: 0, lg: 0.5 }}
            >
              {name}
            </Heading>
          </Stack>

          <Flex mt={{ base: 2, lg: 4 }}>
            <Box w={{ base: '40%', md: "25%" }}>
              <Text
                pr={5}
                pb={1}
                mb={1}
                borderBottom="solid 1px"
                borderBottomColor={useColorModeValue("gray.100", "whiteAlpha.300")}
                fontSize="xs"
              >
                <FormattedMessage id="ad.price" />
              </Text>
              <Box pr={5}>
                <PriceCtn value={price} currency={currency} />
                {/* <Badge as="span" colorScheme="green" fontSize="sm">
                    {(price > 0) ?
                      <NumberFormat
                        value={price}
                        thousandSeparator={" "}
                        suffix=" €"
                        displayType="text"
                      />
                      : "-"}
                  </Badge> */}
              </Box>
            </Box>
            <Box w={{ base: '50%', md: "30%" }}>
              <Text
                pr={5}
                pb={1}
                mb={1}
                borderBottom="solid 1px"
                borderBottomColor={useColorModeValue("gray.100", "whiteAlpha.300")}
                fontSize="xs"
              >

                <FormattedMessage id="ad.area" />
              </Text>
              <Text
                pr={5}
                fontWeight="600"
                fontSize={{ base: "12px", lg: "sm" }}
              >
                {countryToFlag(country)}{region ? `${' '}${region}` : '-'}
              </Text>
            </Box>
            <Box
              display={{ base: 'none', md: 'initial' }}
              w={{ base: '50%', md: "25%" }}>
              <Text
                pr={5}
                pb={1}
                mb={1}
                borderBottom="solid 1px"
                borderBottomColor={useColorModeValue("gray.100", "whiteAlpha.300")}
                fontSize="xs"
              >
                {
                  isBoat ? <FormattedMessage id="ad.builder" /> : isSail ? <FormattedMessage id="ad.sail.maker" /> : <FormattedMessage id="ad.brand" />
                }
              </Text>
              <Text
                pr={5}
                fontWeight="600"
                fontSize="sm"
              >
                {brand ? brand : '-'}
              </Text>
            </Box>
            <Box
              display={{ base: 'none', md: 'initial' }}
              w={{ base: '50%', md: "20%" }}>
              <Text
                pr={5}
                pb={1}
                mb={1}
                borderBottom="solid 1px"
                borderBottomColor={useColorModeValue("gray.100", "whiteAlpha.300")}
                fontSize="xs"
              >
                Publication
              </Text>
              <Text
                pr={5}
                fontWeight="600"
                fontSize="sm"
              >
                {publicationDate}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Flex
          display={{ base: 'none', lg: 'flex' }}
          justifyContent="flex-end"
          wrap="wrap"
        >
          {/* <Box>ADD to favorite feature to reword</Box> */}
          <AddToFavorite adID={contentful_id} />
          <Text
            w={'100%'}
            textAlign="right"
          >
            {isPremium ? <Badge as="span" colorScheme="green">Top 👍</Badge> : null}
          </Text>
        </Flex>
      </Grid>
    </Box >
  )
}


export default AdCardLandscape
