import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import AuthService from '../../../services/auth.service'
import { Formik, Field, Form } from 'formik'
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    FormHelperText,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    FormErrorMessage
} from "@chakra-ui/react";
import Axios from 'axios'

import * as yup from 'yup';
import { BiBell } from 'react-icons/bi'

export default class ModelAlertSubscribeForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: null,
            isRecorded: false,
            isAlreadySubscriber: false
        }
    }
    componentDidMount() {
        // let userData = localStorage.getItem('WbUser')
        const user = AuthService.getCurrentUser()
        if (user) {
            this.setState({
                'userId': user.userId
            },
                this.checkIsAlreadySubscriber(user.userId)

            )
        }
    }
    checkIsAlreadySubscriber(userId) {
        Axios.get(`${process.env.GATSBY_API_URL}/alert/user/${userId}`)
            .then((response) => {
                let userAlerts = response.data
                for (let index = 0; index < userAlerts.length; index++) {
                    const alert = userAlerts[index];
                    if (alert.modelId === this.props.modelId) {
                        this.setState({ isAlreadySubscriber: true })
                    }

                }
            })
    }

    handleSubmit = async (values) => {

    }
    form = (name, placeholder) => (
        <Formik
            initialValues={{ email: '' }}
            validationSchema={
                yup.object().shape({
                    email: yup.string().email('Invalid email').required('Required'),
                })
            }
            onSubmit={(values) => this.handleSubmit(values)}

        >
            {({ values, errors, setFieldValue }) => (
                <Box
                    as={Form}
                >
                    <Field name="email">
                        {({ field, meta }) => (
                            <FormControl isInvalid={meta.error && meta.touched}>
                                <FormLabel htmlFor="email" fontSize={{ base: '13px', lg: '16px' }} color="white">
                                    <FormattedMessage id="model.subscribe.form.title" values={{ modelName: name }} />&nbsp;:
                                </FormLabel>
                                <InputGroup size="md">
                                    <>
                                        <Input
                                            key={'email-field'}
                                            bgColor='white'
                                            pr="4.5rem"
                                            isRequired
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder={placeholder ? placeholder : "eric@tabarly.fr"}
                                            {...field}
                                        />
                                    </>

                                    <InputRightElement zIndex="2" width="auto">
                                        <Button
                                            type="submit"
                                            color="white"
                                            backgroundColor="green.600"
                                            _hover={{
                                                backgroundColor: "green.700"
                                            }}
                                            mr={2}
                                            h="1.75rem"
                                            px={"1rem"}
                                            size="sm"
                                        >
                                            <FormattedMessage id="model.subscribe.form.validate" />
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                <Flex>
                                    <FormErrorMessage bgColor='red.500' color='white' px={2} py={1}>
                                        Oups Capitaine, cette email est erronée.
                                    </FormErrorMessage>
                                </Flex>

                                <FormHelperText id="email-helper-text" color='whiteAlpha.700'>
                                    <FormattedMessage id="model.subscribe.form.message" />...
                                </FormHelperText>
                            </FormControl>
                        )}

                    </Field>

                </Box>
            )}
        </Formik>
    )

    render() {
        const { name, placeholder } = this.props;
        const { isRecorded, isAlreadySubscriber, userId } = this.state;
        const isRecordedMessage = (
            <Text>
                <span aria-label="Parfait" role="img">👍</span>{' '}
                <FormattedMessage id="model.subscribe.form.confirmation" values={{ modelName: name }} />
            </Text>
        )
        return (
            !userId ?
                <Box
                    p={{ base: 2, xl: 4 }}
                    borderRadius={{ base: "3px", xl: 4 }}
                    bgColor='blue.brand'
                    mb={4}
                    display={isAlreadySubscriber ? 'none' : 'block'}
                >
                    {(isRecorded) ? isRecordedMessage : this.form(name, placeholder)}
                </Box>
                :
                !isRecorded ?
                    <Flex
                        alignItems="center"
                        overflow='hidden'
                        display={isAlreadySubscriber ? 'none' : 'flex'}
                    >
                        <Flex
                            bgColor='primary'
                            borderRadius='4px'
                            p={3}
                        >
                            <Text
                                h='40px'
                                p='.5rem 1rem'
                                color='white'
                                bgColor='primary'
                                display={{ base: 'none', lg: 'block' }}
                            >
                                <FormattedMessage id="model.subscribe.form.interested.by" values={{ modelName: name }} />?
                            </Text>
                            <Button
                                onClick={this.handleSubmit}
                                rightIcon={<BiBell />}
                                colorScheme="green"
                            >
                                <FormattedMessage id="model.subscribe.button.label" />
                            </Button>
                        </Flex>
                    </Flex>
                    : isRecordedMessage

        )
    }
}