import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { ThemeProvider, CSSReset, Box } from '@chakra-ui/react'
import { IntlProvider } from 'react-intl'
import theme2 from "../../theme/"

import '../../assets/fonts/stylesheet.css'
import messagesFr from '../locales/fr'
import messagesEn from '../locales/en'
let lang = process.env.GATSBY_LANG
const messages = { ...messagesFr, ...messagesEn }

let i18nConfig = {
    locale: lang,
    messages: messages
};

const formats = {
    number: {
        EUR: {
            style: 'currency',
            currency: 'EUR'
        },
        GBP: {
            style: 'currency',
            currency: 'GBP'
        },
        USD: {
            style: 'currency',
            currency: 'USD'
        }
    }
};

const LayoutExport = ({ children }) => {
    return (
        <IntlProvider
            locale={lang}
            locale={lang}
            formats={formats}
            defaultFormats={formats}
            defaultLocale={i18nConfig.locale}
            messages={i18nConfig.messages[lang]}
        >
            <ThemeProvider theme={theme2}>
                <CSSReset />
                <Helmet>
                    <script
                        src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.2.1/iframeResizer.contentWindow.js"
                        type="text/javascript"
                    ></script>
                </Helmet>
                <Box
                    minHeight="100vh"
                    background="transparent"
                    as="main"
                    id="main"
                    data-iframe-height
                >
                    {children}
                </Box>
            </ThemeProvider>
        </IntlProvider>
    )
}

LayoutExport.propTypes = {
    children: PropTypes.node.isRequired,
}

export default LayoutExport