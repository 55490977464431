import React, { Component } from "react"
import {
    Box
} from "@chakra-ui/react"
import AdCardLandscape from './Landscape'
import AdLoader from './AdLoader'
import Debug from '../../utils/Debug'
class StaticAdsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ads: [],
            adSingleID: null,
            universID: this.props.universID,
            modelID: this.props.modelID,
            hasMore: true,
            currentPage: 0
        }
    }

    render() {
        const { adSingleID } = this.state
        var { ads } = this.props
        ads = ads.edges
        const { filters } = this.props

        return (
            <>
            {/* <Debug data={ this.state.adSingleID} /> */}
                <Box key={filters} py="1rem">
                    {ads.length ?
                        ads.map((edge) =>
                            adSingleID === edge.node.contentful_id ?

                                <Box mb="2rem">
                                    <AdLoader
                                        adID={adSingleID}
                                        handleUnload={
                                            () => {
                                                this.setState({ adSingleID: null })
                                            }
                                        }
                                    />
                                </Box>
                                :
                                // <Debug data={ edge.node.contentful_id } />
                                <AdCardLandscape
                                    to={edge.node.slug}
                                    openAction={
                                        () => {
                                            this.setState({ adSingleID: edge.node.contentful_id})
                                        }
                                    }
                                    isInIframe={true}
                                    action={edge.node.action}
                                    name={edge.node.name}
                                    price={edge.node.price}
                                    brand={(edge.node.refBrand) ? edge.node.refBrand.name : null}
                                    // date={edge.fields.date ? <DateYear date={edge.fields.date} /> : '-'}
                                    region={edge.node.region}
                                    country={edge.node.country}
                                    department={edge.node.department}
                                    key={edge.node.contentful_id}
                                    images={edge.node.pictures}
                                    seller={edge.node.user ? true : false}
                                    publicationDate={edge.node.publicationDate}
                                />
                        ) : null}
                </Box>
            </>
        )
    }
}

export default StaticAdsList